<template lang="pug">
Dialog.dialogCadastro(
        :header="dialogConsultarCEP ? 'Consultar CEP' : `Cadastro de ${ label ?? 'Pessoa Júridica' }`"
        :visible.sync='show'
        :class="{ 'overflow-visible':dialogConsultarCEP, 'dialogCadastro':true }"
        @show='dialogConsultarCEP = false'
        :modal='true')

        .waitingPaciente(v-show='waitingPaciente')
            ProgressSpinner(strokeWidth='2')

        div(v-show='!waitingPaciente')

            form(@submit.prevent='handleSubmitCEP()' v-show='dialogConsultarCEP')
                .p-grid.p-fluid.p-align-end
                    .p-col-12
                        label.form-label Logradouro:
                        InputText(v-model='dialogConsultarCEP_data.logradouro' :disabled='dialogConsultarCEP_data.result.length > 0')

                    .p-col-6
                        label.form-label Estado:
                        Dropdown(v-model='dialogConsultarCEP_data.estado'
                            :disabled='dialogConsultarCEP_data.result.length > 0'   
                            :options='options.ufs'
                            @change='dialogConsultarCEP_data.cidade = null; getMunicipiosEndereco(true)'
                            optionLabel='nm_estado'
                            optionValue='ds_sigla'
                            placeholder='Selecione'
                            :filter='options.ufs.length > 0')

                    .p-col-6
                        label.form-label Município:
                        //Dropdown(v-model='dialogConsultarCEP_data.cidade'
                            :disabled='dialogConsultarCEP_data.result.length > 0'
                            :options='options.cep_municipiosEndereco'
                            @change='getMunicipiosEndereco()'
                            optionLabel='text'
                            optionValue='text'
                            placeholder='Selecione'
                            :filter='options.municipiosEndereco.length > 0')
                        Dropdown(v-model='dialogConsultarCEP_data.cidade'
                            :disabled='dialogConsultarCEP_data.result.length > 0'
                            :options='options.cep_municipiosEndereco'
                            optionLabel='text'
                            optionValue='text'
                            placeholder='Selecione'
                            :filter='options.cep_municipiosEndereco.length > 0')

                    .p-col-12(v-if='dialogConsultarCEP_data.result.length')
                        DataTable.datatable-cep(:value='dialogConsultarCEP_data.result' selectionMode='single' @row-select='onRowSelectCEP($event)')
                            Column(headerStyle='width: 30%;' bodyStyle='text-align:center' field='cep' header='CEP')
                            Column(headerStyle='width: 40%;' field='logradouro' header='Logradouro')
                            Column(headerStyle='width: 40%;' field='bairro' header='Bairro')
                        .ta-center.mt-3
                            Button.p-button-danger(label='Limpar' icon='jam jam-rubber' style='max-width:160px'
                                @click='dialogConsultarCEP_data.result = []')

                    .p-col-12.mt-1(v-else)
                        .p-grid
                            .p-col-6
                                Button.p-button-secondary.mr-2(type='button' label='Voltar' icon='jam jam-chevron-left' style='max-width:160px' @click='dialogConsultarCEP=false')
                            .p-col-6.ta-right
                                ProgressSpinner.waitingCEPDialog(v-if='waitingCEPDialog' strokeWidth='6')
                                Button(v-else label='Consultar' icon='jam jam-search' style='max-width:160px')

            form(@submit.prevent='handleSubmit()' v-show='!dialogConsultarCEP')
                .p-grid.p-fluid.p-align-end

                    .p-col-12.p-md-6(:class="{ 'form-group--error': submitted && $v.model.nm_pessoa_fisica.$error }")
                        label.form-label Nome:
                        InputText.text-readonly(:disabled='medEmpresa' type='text' v-model='$v.model.nm_pessoa_fisica.$model' :readonly='model.id && usuarioNivel < 5 && usuarioNivel != 6')
                        .feedback--errors(v-if='submitted && $v.model.nm_pessoa_fisica.$error')
                            .form-message--error(v-if="!$v.model.nm_pessoa_fisica.minLength") <b>Nome</b> deve ter pelo menos 2 caracteres.
                            .form-message--error(v-if="!$v.model.nm_pessoa_fisica.required") <b>Nome</b> é obrigatório.

                    .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nr_cpf.$error }")
                        label.form-label CPF:
                        InputMask.text-readonly(:disabled='medEmpresa' type='tel' v-model='$v.model.nr_cpf.$model' mask='999.999.999-99' :placeholder="model.responsavel ? '(Opcional)' : ''")
                        .feedback--errors(v-if='submitted && $v.model.nr_cpf.$error')
                            .form-message--error(v-if="!$v.model.nr_cpf.validateCpf") <b>CPF</b> inválido.
                            .form-message--error(v-if="!$v.model.nr_cpf.required") <b>CPF</b> é obrigatório.

                    .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.dt_nascimento.$error }")
                        label.form-label Data de nascimento:
                        InputMask(:disabled='medEmpresa' type='tel' v-model='$v.model.dt_nascimento.$model' mask='99/99/9999' placeholder='dd/mm/aaaa')
                        .feedback--errors(v-if='submitted && $v.model.dt_nascimento.$error')
                            .form-message--error(v-if="!$v.model.dt_nascimento.dateValid") <b>Data de nascimento</b> é inválida.

                    .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.ie_sexo.$error }")
                        label.form-label Sexo:
                        SelectButton.input-sexo(:disabled='medEmpresa' type='text' v-model='$v.model.ie_sexo.$model' :options='options.ie_sexo' optionLabel='text' optionValue='value')
                        .feedback--errors(v-if='submitted && $v.model.ie_sexo.$error')
                            .form-message--error(v-if="!$v.model.ie_sexo.required") <b>Sexo</b> é obrigatório.

                    .p-col-12.p-md-5(:class="{ 'form-group--error': submitted && $v.model.ds_email.$error }")
                        label.form-label E-mail:
                        InputText(:disabled='medEmpresa' type='text' v-model='$v.model.ds_email.$model' placeholder='(Opcional)')
                        .feedback--errors(v-if='submitted && $v.model.ds_email.$error')
                            .form-message--error(v-if="!$v.model.ds_email.email") <b>E-mail</b> é inválido.

                    .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nr_telefone.$error }")
                        label.form-label Telefone:
                        InputMask(:disabled='medEmpresa' type='tel' v-model='$v.model.nr_telefone.$model' mask='(99) 99999999?9')
                        .feedback--errors(v-if='submitted && $v.model.nr_telefone.$error')
                            .form-message--error(v-if="!$v.model.nr_telefone.validateFone") <b>Telefone</b> é inválido.

                    .p-col-12(v-if='waitingResponsavel')
                        ProgressBar(mode='indeterminate')

                    .p-col-12(v-else)
                        form(v-if='!buscaResponsavel.result.length' @submit.prevent='getResponsavel()')
                            label.form-label
                                span Responsável:&nbsp;
                                span.text-obs-responsavel(v-if='!model.responsavel') (Deixar em branco se não houver)
                            .p-inputgroup(v-if='!model.responsavel')
                                Dropdown.dropdown-buscaResponsavel(:disabled='medEmpresa' v-model='buscaResponsavel.field' :options='options.buscaResponsavel'
                                    optionLabel='text' optionValue='value' style='width:33%;text-align:right' @change='buscaResponsavel.value = null')
                                InputMask(v-if="buscaResponsavel.field === 'nr_cpf'" type='tel' :disabled='!buscaResponsavel.field || medEmpresa'
                                    v-model='buscaResponsavel.value' mask='999.999.999-99' placeholder='(Não possui)')
                                InputText(:disabled='medEmpresa' v-else v-model='buscaResponsavel.value' placeholder='(Não possui)')
                                Button(icon='jam jam-search' :disabled='!buscaResponsavel.value' v-if='!medEmpresa')
                            .p-inputgroup(v-else)
                                InputText.text-readonly(:disabled='medEmpresa' v-model='model.responsavel.nm_pessoa_fisica_f' readonly)
                                Button.p-button-danger(v-if='!medEmpresa' icon='jam jam-rubber' @click='buscaResponsavel.result = []; model.responsavel = null')
                        div(v-else)
                            label.form-label Responsável:
                            .p-inputgroup
                                Dropdown(:disabled='medEmpresa' v-model='model.responsavel' :options='buscaResponsavel.result'
                                    optionLabel='nm_pessoa_fisica_f' optionValue='id')
                                Button.p-button-danger(v-if='!medEmpresa' icon='jam jam-rubber' label='Limpar'
                                    @click='buscaResponsavel.result = []; model.responsavel = null')

                    .p-col-12
                        .p-grid.p-fluid.p-align-end.p-justify-end

                            .p-col-12
                                h4.text-box
                                    span Endereço
                                    .divider

                            .p-col-12.p-md-4(style='position:relative' :class="{ 'form-group--error': submitted && $v.model.nr_cep.$error }")
                                label.form-label CEP:
                                InputMask(type='tel' v-model='$v.model.nr_cep.$model' mask='99999-999' @blur='searchCEP()' :disabled='waitingCEP || medEmpresa')
                                .feedback--errors(v-if='submitted && $v.model.nr_cep.$error')
                                    .form-message--error(v-if="!$v.model.nr_cep.required") <b>CEP</b> é obrigatório.
                                    .form-message--error(v-if="!$v.model.nr_cep.minLength") <b>CEP</b> inválido.
                                .waitingCEP(v-if='waitingCEP')
                                    ProgressSpinner(strokeWidth='6')
                                a.link-naoseimeucep(v-if='!medEmpresa' href='javascript:;' @click="naoSeiMeuCEP_open()") Não sei meu CEP

                            .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.cd_estado.$error }")
                                label.form-label Estado:
                                Dropdown(v-model='$v.model.cd_estado.$model' :options='options.ufs' @change='model.cd_cidade = null; getMunicipiosEndereco()'
                                    optionLabel='nm_estado' optionValue='id' placeholder='Selecione' :filter='options.ufs.length > 0' :disabled='waitingCEP || medEmpresa')
                                .feedback--errors(v-if='submitted && $v.model.cd_estado.$error')
                                    .form-message--error(v-if="!$v.model.cd_estado.required") <b>Estado</b> é obrigatório.

                            .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.cd_cidade.$error }")
                                label.form-label Município:
                                Dropdown(v-model='$v.model.cd_cidade.$model' :options='options.municipiosEndereco' :filter='options.municipiosEndereco.length > 0' :disabled='waitingCEP || medEmpresa'
                                    optionLabel='text' optionValue='value' placeholder='Selecione' v-tooltip.bottom="model.cd_estado == null ? 'Informe o estado antes' : ''")
                                .feedback--errors(v-if='submitted && $v.model.cd_cidade.$error')
                                    .form-message--error(v-if="!$v.model.cd_cidade.required") <b>Município</b> é obrigatório.

                            .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.nm_bairro.$error }")
                                label.form-label Bairro:
                                InputText(type='text' v-model='$v.model.nm_bairro.$model' :disabled='waitingCEP || medEmpresa')
                                .feedback--errors(v-if='submitted && $v.model.nm_bairro.$error')
                                    .form-message--error(v-if="!$v.model.nm_bairro.required") <b>Bairro</b> é obrigatório.

                            .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.nm_rua.$error }")
                                label.form-label Rua/Logradouro:
                                InputText(type='text' v-model='$v.model.nm_rua.$model' :disabled='waitingCEP || medEmpresa')
                                .feedback--errors(v-if='submitted && $v.model.nm_rua.$error')
                                    .form-message--error(v-if="!$v.model.nm_rua.required") <b>Rua</b> é obrigatório.

                            .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.nr_numero.$error }")
                                label.form-label Número:
                                InputText(v-model='$v.model.nr_numero.$model' type='tel' :disabled='waitingCEP || medEmpresa')
                                .feedback--errors(v-if='submitted && $v.model.nr_numero.$error')
                                    .form-message--error(v-if="!$v.model.nr_numero.required") <b>Número</b> é obrigatório.

                            .p-col-12.mt-3
                                label.form-label Observações:
                                Textarea.textarea-informacoes(:disabled='medEmpresa' v-model='$v.model.ds_observacao.$model' placeholder='(Opcional)' :autoResize="true" rows="5")

                    .p-col-12.ta-center(v-if="!medEmpresa")
                        .waitingForm(v-if='waitingForm || waitingCarteira')
                            ProgressSpinner(strokeWidth='4')
                        div(v-else)
                            Button(label='Salvar' type="submit" icon='jam jam-save' style='max-width:160px')
                            Button.ml-2.p-button-warning(v-if='model.id' label='Imprimir carteira' style='max-width:160px' icon='jam jam-id-card' type='button' @click='imprimirCarteira()')
                            Button.ml-2(label='Boas Vindas' style='max-width:160px; backgroundColor: #C16AF3; border: 1px solid #C16AF3;' icon='jam jam-printer' type='button' @click='imprimirBoasVindas()')
</template>

<style lang="scss">
    .dialogCadastro.overflow-visible {
        .p-dialog-content {
            overflow: visible !important;
        }
    }
    .p-dialog-mask {
        //align-items: flex-start;
        padding-top: 40px;
    }
    .dialogCadastro {
        width: 96%;
        max-width: 720px;
        .waitingPaciente {
            text-align: center;
            .p-progress-spinner {
                margin: 40px 0;
                width: 60px;
                height: auto;
            }
        }
        .dropdown-buscaResponsavel {
            .p-dropdown-label {
                font-weight: 700 !important;
            }
        }
        .text-obs-responsavel {
            color: #888;
            font-size: 12px;
        }

        .text-readonly[readonly=readonly] {
            font-weight: 700;
            background-color: #ddd;
            color: #666;
        }
        .text-box {
            text-align: center;
            font-size: 16px;
            margin: 0;
            position: relative;
            span {
                background-color: #fff;
                display: inline-block;
                z-index: 10;
                position: relative;
                padding: 0 10px;
                color: #888;
            }
            .divider {
                z-index: 9;
                position: absolute;
                top: 12px;
                left: 0;
                right: 0;
                height: 1px;
                background-color: #888;
            }
        }
        .waitingCEP {
            position: absolute;
            top: 34px;
            right: 20px;
            .p-progress-spinner {
                width: 20px;
                height: auto;
            }
        }
        .waitingForm {
            text-align: center;
            display: inline-block;
            vertical-align: middle;
            .p-progress-spinner {
                width: 28px;
                height: auto;
            }
        }
        .link-naoseimeucep {
            position: absolute;
            right: 8px;
            bottom: -10px;
            font-size: 13px;
            font-weight: 700;
        }
        .datatable-cep {
            td { font-size: 13px; }
            max-height: 200px;
            overflow-y: auto;
        }
        .waitingCEPDialog {
            width: 25px;
            height: auto;
        }
        .p-dropdown.p-disabled .p-inputtext, .p-inputtext[disabled=disabled] {
            color: #000;
            font-weight: 600;
            background-color: #ddd;
        }
    }
</style>

<script>
    import Dialog from 'primevue/dialog'
    import InputText from 'primevue/inputtext'
    import Textarea from 'primevue/textarea'
    import InputMask from 'primevue/inputmask'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Tooltip from 'primevue/tooltip'
    import ProgressSpinner from 'primevue/progressspinner'
    import ProgressBar from 'primevue/progressbar'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'

    import { Utils } from '../../middleware'
    import { required, requiredIf, minLength, email } from 'vuelidate/lib/validators'
    import { validateCpf, validateFone, fixTelefoneFormat } from '../../utils'
    import moment from 'moment'
    import Methods from '../Paciente/Methods'

    export default {
        components: { Dialog, InputText, InputMask, Button, Dropdown, SelectButton,
            Tooltip, ProgressSpinner, ProgressBar, DataTable, Column, Textarea },
        directives: { tooltip: Tooltip },
        watch: {
            'bpModel': function (model) {
                if (model) {
                    this.model = Object.assign({}, model)
                    if (this.model.responsavel && typeof this.model.responsavel === 'object') {
                            if (this.model.responsavel.nr_cpf) {
                                this.model.responsavel.nr_cpf_f = this.model.responsavel.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                                this.model.responsavel.nm_pessoa_fisica_f = `${ this.model.responsavel.nm_pessoa_fisica } (${ this.model.responsavel.nr_cpf_f })`
                            } else {
                                this.model.responsavel.nm_pessoa_fisica_f = `${ this.model.responsavel.nm_pessoa_fisica }`
                            }
                    }
                    this.model.nr_telefone = fixTelefoneFormat(this.model.nr_telefone)
                    if (this.model.cd_cidade) {
                        this.model.cd_estado = this.model.cd_cidade.cd_estado.id
                        this.model.cd_cidade = this.model.cd_cidade.id
                    }
                    this.model.dt_nascimento = model.dt_nascimento_f
                    this.submitted = false
                    this.getMunicipiosEndereco()
                } else {
                    this.model = {
                        cd_pai: null,
                        nm_pessoa_fisica: '',
                        nr_cpf: '',
                        nr_telefone: '',
                        dt_nascimento: '',
                        ie_sexo: 'M',
                        ds_email: '',
                        nm_bairro: '',
                        nm_rua: '',
                        nr_numero: '',
                        nr_cep: '',
                        cd_cidade: null,
                        cd_estado: null,
                        ds_observacao: '',
                        cd_titular: null
                    }
                    this.submitted = false
                }
            }
        },
        created () {
            this.waiting = true
            this.hasError = false
            Utils.getUFs().then(response => {
                this.options.ufs.push({ value: null, text: '- Selecione -', textAlt: '- Selecione -' })
                if (response.status == 200) this.options.ufs = response.data
                let usuario = JSON.parse(localStorage.getItem('usuario'))
                this.usuarioNivel = usuario.nivel
                this.waiting = false
            })
        },
        props: ['dialogCadastro', 'label', 'waitingPaciente', 'bpModel', 'medEmpresa'],
        data () {
            return {
                hasError: false,
                model: {
                    cd_pai: null,
                    nm_pessoa_fisica: '',
                    nr_cpf: '',
                    nr_telefone: '',
                    dt_nascimento: '',
                    ie_sexo: null,
                    ds_email: '',
                    nm_bairro: '',
                    nm_rua: '',
                    nr_numero: '',
                    nr_cep: '',
                    cd_cidade: null,
                    cd_estado: null,
                    cd_titular: null,
                },
                buscaResponsavel: {
                    field: 'nm_pessoa_fisica',
                    value: null,
                    result: []
                },
                options: {
                    ie_sexo: [
                        { text: 'Masculino', value: 'M' },
                        { text: 'Feminino', value: 'F' }
                    ],
                    buscaResponsavel: [
                        { text: 'Nome:', value: 'nm_pessoa_fisica' },
                        { text: 'CPF:', value: 'nr_cpf' }
                    ],
                    municipiosEndereco: [],
                    cep_municipiosEndereco: [],
                    ufs: []
                },
                dialogConsultarCEP: false,
                dialogConsultarCEP_data: {
                    logradouro: null,
                    cidade: null,
                    estado: null,
                    result: []
                },
                waiting: true,
                waitingResponsavel: false,
                waitingCEPDialog: false,
                waitingCarteira: false,
                waitingCEP: false,
                waitingForm: false,
                submitted: false,
                isBuscaPacienteModal: true,
                usuarioNivel: 0,
                dCadastro: false,
                pai: null
            }
        },
        validations () {
            let v = {
                model: {
                    nm_pessoa_fisica: { required, minLength: minLength(2) },
                    nr_cpf: {
                        required: requiredIf(function(model) { return model.responsavel === null }),
                        validateCpf: this.model.nr_cpf ? validateCpf : true
                    },
                    nr_telefone: { validateFone },
                    dt_nascimento: { dateValid: val => moment(val, 'DD/MM/YYYY').isValid() },
                    ds_email: { email },
                    ie_sexo: { required },
                    nr_cep: { required: requiredIf(function(model) { return model.responsavel === null }), minLength: minLength(8) },
                    cd_cidade: {required: requiredIf(function(model) { return model.responsavel === null }) },
                    cd_estado: { required: requiredIf(function(model) { return model.responsavel === null }) },
                    nm_rua: { required: requiredIf(function(model) { return model.responsavel === null }) },
                    nr_numero: { required: requiredIf(function(model) { return model.responsavel === null }) },
                    nm_bairro: { required: requiredIf(function(model) { return model.responsavel === null }) },
                    ds_observacao: { },
                }
            }
            return v
        },
        computed: {
            show: {
                get() { return this.dialogCadastro },
                set(value) { if (!value) this.$emit('close') }
            }
        },
        methods: {
            getMunicipiosEndereco (modal = false) {
                if (modal) Methods.getMunicipiosEnderecoModal(this)
                else Methods.getMunicipiosEndereco(this)
            },
			imprimirBoasVindas () { Methods.imprimirBoasVindas(this) },
            imprimirCarteira () { Methods.imprimirCarteira(this) },
            naoSeiMeuCEP_open () { Methods.naoSeiMeuCEP_open(this) },
            onRowSelectCEP (ev) { Methods.onRowSelectCEP(this, ev) },
            getResponsavel () { Methods.getResponsavel(this) },
            handleSubmitCEP () { Methods.handleSubmitCEP(this) },
            handleSubmit () {
                if(this.medEmpresa) {
                    this.$toast.error('Não é possível alterar dados relacionados ao MedClub Empresa')
                } else Methods.handleSubmit(this)
            },
            searchCEP () { Methods.searchCEP(this) }
        }
    }
</script>
