import { Utils, Paciente } from './../../middleware'
import { saveAs } from 'file-saver'
import moment from 'moment'

const getMunicipiosEndereco = (vm) => {
    vm.options.municipiosEndereco = []
    if (vm.model.cd_estado)
        Utils.getMunicipiosEstadoId(vm.model.cd_estado).then(response => {
            vm.options.municipiosEndereco.push({ value: null, text: '- Selecione -' })
            if (response.status == 200)
                response.data.forEach(mun => vm.options.municipiosEndereco.push({
                    value: mun.id,
                    text: mun.nm_cidade,
                    obj: mun
                }))
        })
}

const getMunicipiosEnderecoModal = (vm) => {
    vm.options.municipiosEndereco = []
    vm.options.cep_municipiosEndereco = []
    Utils.getMunicipiosByUF(vm.dialogConsultarCEP_data.estado).then(response => {
        vm.options.cep_municipiosEndereco.push({ value: null, text: '- Selecione -' })
        if (response.status == 200)
            response.data.forEach(mun =>
                vm.options.cep_municipiosEndereco.push({
                    value: mun.id,
                    text: mun.nm_cidade
                }))
    })

}

const imprimirBoasVindas = (vm, id) => {
	// console.log(props.data.id);
	vm.waitingBoasVindasId = id ? id : vm.model.id;
	Paciente.imprimirBoasVindas(id ? id : vm.model.id).then(response => {
		vm.waitingBoasVindasId = 0;
		if (([200, 201, 204]).includes(response.status)) {
			if (response.data.detail) {
				vm.$toast.error(response.data.detail, { duration: 3000 })
			} else {
				let blob = new Blob([response.data], { type: 'application/pdf; charset=utf-8' })
				var fileURL = URL.createObjectURL(blob);
				let a = window.open(fileURL);
				a.print()

				//let blob = new Blob([response.data], { type: 'application/pdf; charset=utf-8' })
				//let fd = new FormData()
				//fd.append('file', blob)
				//fetch('http://localhost:9990/imprimir-cartao', { method: 'POST', body: fd })
			}
		} else if (response.status == 400) {
			if (response.data.detail) {
				if (typeof response.data.detail == 'string')
					vm.$toast.error(response.data.detail, { duration: 3000 })
				else response.data.detail.forEach(msg => vm.$toast.error(msg, { duration: 3000 }))
			}
		}
	})
}

const imprimirCarteira = (vm) => {
    vm.waitingCarteira = true
    Paciente.imprimirCarteira(vm.model.id).then(response => {
        vm.waitingCarteira = false
        if (([200, 201, 204]).includes(response.status)) {
            if (response.data.detail) {
                vm.$toast.error(response.data.detail, { duration: 3000 })
            } else {
                let blob = new Blob([response.data], { type: 'application/pdf; charset=utf-8' })
                saveAs(blob, `carteira-paciente-${ vm.model.id }.pdf`)
                //var fileURL = URL.createObjectURL(blob);
                //let a = window.open(fileURL);
                //a.print()

                //let blob = new Blob([response.data], { type: 'application/pdf; charset=utf-8' })
                //let fd = new FormData()
                //fd.append('file', blob)
                //fetch('http://localhost:9990/imprimir-cartao', { method: 'POST', body: fd })
            }
        } else if (response.status == 400) {
            if (response.data.detail) {
                if (typeof response.data.detail == 'string')
                    vm.$toast.error(response.data.detail, { duration: 3000 })
                else response.data.detail.forEach(msg => vm.$toast.error(msg, { duration: 3000 }))
            }
        }
    })
}

const naoSeiMeuCEP_open = (vm) => {
    /*
    vm.options.municipiosEndereco = []
    vm.model.nr_cep = null
    vm.model.cd_estado = null
    vm.model.nm_rua = null
    vm.model.cd_cidade = null
    vm.model.nm_bairro = null
    */
   vm.dialogConsultarCEP_data = {
        logradouro: null,
        cidade: null,
        estado: null,
        result: []
    }
    vm.dialogConsultarCEP = true
}

const onRowSelectCEP = (vm, ev) => {
    vm.model.nr_cep = ev.data.cep
    if (ev.data.logradouro) vm.model.nm_rua = ev.data.logradouro
    if (ev.data.bairro) vm.model.nm_bairro = ev.data.bairro
    vm.model.cd_estado = ev.data.cd_cidade.cd_estado.id
    vm.model.cd_cidade = ev.data.cd_cidade.id
    vm.dialogConsultarCEP = false
    window.setTimeout(() => {
        vm.options.municipiosEndereco = [
            { value: ev.data.cd_cidade.id, text: ev.data.cd_cidade.nm_cidade }
        ]
    })
}

const getResponsavel = (vm) => {
    let params = { titular: true }
    vm.buscaResponsavel.result = []
    if (vm.buscaResponsavel.field == 'nr_cpf') {
        params.nr_cpf = vm.buscaResponsavel.value.replace(/(\.|-|_)/g, '')
    } else params[vm.buscaResponsavel.field] = vm.buscaResponsavel.value
    vm.waitingResponsavel = true
    Paciente.findAll(params).then(response => {
        vm.waitingResponsavel = false
        if (response.status == 200) {
            if (response.data.length) {
                vm.buscaResponsavel.value = null
                response.data.forEach(item => {
                    if (item.nr_cpf && !item.cd_pai && item.id != vm.model.id) {
                        item.nr_cpf_f = item.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                        item.nm_pessoa_fisica_f = `${ item.nm_pessoa_fisica } (${ item.nr_cpf_f })`
                        vm.buscaResponsavel.result.push(item)
                    }
                })
                if (vm.buscaResponsavel.result.length) {
                    vm.model.cd_pai = vm.buscaResponsavel.result[0].id
                    vm.buscaResponsavel.result.unshift({ id: null, nm_pessoa_fisica_f: '- Selecione -' })
                } else vm.$toast.error('Pesquisa sem resultado', { duration: 3000 })
            } else vm.$toast.error('Pesquisa sem resultado', { duration: 3000 })
        }
    })
}

const handleSubmitCEP = (vm) => {
    vm.dialogConsultarCEP_data.result = []
    if (! vm.dialogConsultarCEP_data.logradouro)
        vm.dialogConsultarCEP_data.logradouro = null

    if (! vm.dialogConsultarCEP_data.estado)
        vm.$toast.error('Informe o estado', { duration: 3000 })
    else if (! vm.dialogConsultarCEP_data.cidade)
        vm.$toast.error('Informe a cidade', { duration: 3000 })
    else {
        vm.waitingCEPDialog = true
        let dataSend = {
            logradouro: vm.dialogConsultarCEP_data.logradouro,
            cidade: vm.dialogConsultarCEP_data.cidade,
            estado: vm.dialogConsultarCEP_data.estado
        }
        Utils.getCEPCidade(dataSend).then(response => {
            vm.waitingCEPDialog = false
            if (response.status === 200) {
                if (response.data.length) {
                    vm.dialogConsultarCEP_data.result = response.data
                } else {
                    vm.$toast.error('Pesquisa sem resultado', { duration: 3000 })
                }
            }
        })
    }
}

const handleSubmit = (vm) => {
    vm.submitted = true

    vm.$v.$touch()
    if (vm.$v.$invalid) return 0

    let dataSend = Object.assign({}, vm.model)
    let clean = ['dt_nascimento_f', 'ie_sexo_f', 'label', 'dt_atualizado', 'dt_criado', 'nm_usuario_edi','nm_usuario_cri']
    if (dataSend.cd_pai) {
        if (typeof dataSend.cd_pai == 'object') dataSend.cd_pai = dataSend.cd_pai.id
        //clean = clean.concat(['cd_cidade', 'dependentes', 'ds_complemento', 'nm_bairro', 'nm_rua', 'nr_cep', 'nr_cpf', 'nr_numero'])
    clean = clean.concat(['dependentes'])
    }
    clean.forEach(item => delete dataSend[item])

    dataSend.dt_nascimento = moment(dataSend.dt_nascimento, 'DD/MM/YYYY').format('YYYY-MM-DD')
    let arrConvertOnlyNumbers = ['nr_cpf', 'nr_telefone', 'nr_cep']
    arrConvertOnlyNumbers.forEach(i => { if (dataSend[i]) dataSend[i] = dataSend[i].match(/\d+/g).join('') })
    if (!dataSend.ds_email) delete dataSend.ds_email

    dataSend.nr_telefone = `+55${ dataSend.nr_telefone }`

    vm.waitingForm = true
    Paciente.save(dataSend).then(response => {
        if (([200, 201]).includes(response.status)) {
            if (vm.isBuscaPacienteModal) { // Finalização do submit do modal
                let paciente = response.data
                paciente.nr_cpf_f = vm.model.nr_cpf
                paciente.dt_nascimento_f = vm.model.dt_nascimento
                paciente.label = paciente.nr_cpf ? `${ paciente.nm_pessoa_fisica } (CPF: ${ paciente.nr_cpf_f })` :
                    `${ paciente.nm_pessoa_fisica } (Data nasc.: ${ paciente.dt_nascimento_f })`
                paciente.ie_sexo_f = vm.options.ie_sexo.find(option => option.value == paciente.ie_sexo).text
                if (paciente.cd_cidade) paciente.cd_cidade = vm.options.municipiosEndereco.find(municipio => municipio.value == paciente.cd_cidade).obj

                vm.$parent.cd_pessoa_fisica = paciente.id
                vm.$parent.list = [paciente]
                setTimeout(() => {
                    vm.$parent.dialogCadastro = false
                    vm.$toast.success('Cadastro salvo com sucesso', { duration: 3000 })
                    if (vm.$parent.$parent.dependentes) {
                        let dependente = vm.$parent.$parent.dependentes.find(dependente => dependente.id = vm.model.id)
                        Object.keys(dependente).forEach(key => { dependente[key] = vm.model[key] })
                    }
                })
            } else { // Finalização do submit da tela do CRUD
                if (! dataSend.id) vm.$router.push({ name: 'paciente' })
                vm.$toast.success('Cadastro salvo com sucesso', { duration: 3000 })
            }
        } else if (([400]).includes(response.status)) {
            if (vm.isBuscaPacienteModal)
                vm.hasError = true
            if (response.data)
                Object.values(response.data).forEach(error => {
                    error.forEach(errorMsg => vm.$toast.error(errorMsg, { duration: 3000 }))
                })
        }
        vm.waitingForm = false
    })
}

const searchCEP = (vm) => {
    let cep = vm.model.nr_cep.match(/\d+/g)
    let clearAddressFields = () => {
        vm.model.nm_rua = null
        vm.model.nm_bairro = null
        vm.model.cd_estado = null
        vm.model.cd_cidade = null
        vm.options.municipiosEndereco = []
    }
    if (cep) {
        cep = cep.join('')
        if (cep.length === 8 && !isNaN(cep)) {
            vm.waitingCEP = true
            Utils.getCEP(cep).then(response => {
                if (response.status === 200) {
                    let resultCEP = response.data
                    if (resultCEP.cd_cidade) {
                        Utils.getMunicipiosByUF(response.data.cd_cidade.cd_estado.ds_sigla).then(() => {
                            if (resultCEP.logradouro) vm.model.nm_rua = resultCEP.logradouro
                            if (resultCEP.bairro) vm.model.nm_bairro = resultCEP.bairro
                            vm.model.cd_estado = resultCEP.cd_cidade.cd_estado.id
                            vm.model.cd_cidade = resultCEP.cd_cidade.id
                            vm.options.municipiosEndereco = [{ value: resultCEP.cd_cidade.id, text: resultCEP.cd_cidade.nm_cidade }]
                            vm.waitingCEP = false
                        })
                    } else {
                        vm.waitingCEP = false
                    }
                } else if (response.status == 400) {
                    vm.waitingCEP = false
                    if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            vm.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => vm.$toast.error(msg, { duration: 3000 }))
                    }
                }
            })
        } else clearAddressFields()
    } // else clearAddressFields()
}

export default {
    getMunicipiosEndereco,
    getMunicipiosEnderecoModal,
    imprimirCarteira,
	imprimirBoasVindas,
    naoSeiMeuCEP_open,
    onRowSelectCEP,
    getResponsavel,
    handleSubmitCEP,
    handleSubmit,
    searchCEP
}
