<template lang="pug">
.main-wrapper.caixas-salvar
    ProgressBar(v-if='waiting' mode="indeterminate")
    div(v-else)
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-3
                    Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                .p-col-9.ta-right
                    h1.text-header.text-secondary MedClub /
                        <router-link :to='{ name: "contas-receber" }'> Contas a Receber</router-link> /
                        <b>{{  model.id ? 'Visualizar' : 'Adicionar' }}</b>
        Dialog.dialogCancelar(header='Cancelar Parcela' :visible.sync='dialogCancelar.visible' :modal='true')
            .ta-center
                p Deseja cancelar a parcela  <b> {{dialogCancelar.data?.nr_parcela}} / {{model.qt_parcelas}} </b>?
            .ta-center.my-4
                ProgressBar(v-if='waitingCancelarParcela' mode='indeterminate' strokeWidth='6')
                Button.p-button-danger(v-else label='Cancelar' @click='cancelarParcela()')
        Dialog.dialogAdicionarBaixar(header="Baixa de Conta a Receber" :modal='true' :visible.sync='dialogBaixa.visible')
            .p-grid.p-fluid
                .p-col-6()
                    label.form-label Data de Recebimento:
                    .p-inputgroup
                        Calendar( dateFormat="dd/mm/yy" :locale="ptbr" v-model='baixaModel.dt_baixa'
                            :manualInput='false' placeholder='DD/MM/YYYY')
                .p-col-6
                .p-col-3
                    label.form-label Desconto:
                    .p-inputgroup
                        span.p-inputgroup-addon R$
                        currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled.ta-right(
                            locale='pt-BR' :auto-decimal-mode='true' v-model='baixaModel.vl_desconto')
                .p-col-3
                    label.form-label Juros:
                    .p-inputgroup
                        span.p-inputgroup-addon R$
                        currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled.ta-right(
                            locale='pt-BR' :auto-decimal-mode='true' v-model='baixaModel.vl_juros')
                .p-col-3
                    label.form-label Multas:
                    .p-inputgroup
                        span.p-inputgroup-addon R$
                        currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled.ta-right(
                            locale='pt-BR' :auto-decimal-mode='true' v-model='baixaModel.vl_multa')
                .p-col-3
                    label.form-label Impostos totais:
                    .p-inputgroup
                        span.p-inputgroup-addon R$
                        currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled.ta-right(
                            locale='pt-BR' :auto-decimal-mode='true' v-model='baixaModel.vl_impostos_totais')

                .p-col-12
                    Panel
                        template(#header)
                            .p-grid.p-fluid(style='margin-top: 1px')
                                .p-col-6
                                    span  <b>Formas de Pagamento</b>
                                .p-col-6.ta-right
                                    span <b>Total: {{ dialogBaixa.data.vl_titulo_f }} </b>
                        .p-grid.p-fluid
                            .p-col-3(v-if='formasPagamentoList.length' v-for='elem, idx in formasPagamentoList' :key='idx')
                                Card.payment-box
                                    template(slot='content')
                                        .ta-right
                                            a.btn-remove-payment(href='javascript:;' @click='formasPagamentoList.splice(idx, 1);')
                                                i.jam.jam-close
                                        .ta-center
                                            div.icon-box(v-if='elem.cd_forma_pagamento == 1')
                                                i.pi.pi-money-bill
                                            div.icon-box(v-if='elem.cd_forma_pagamento == 3')
                                                i.jam.jam-coin
                                            div.icon-box(v-if='elem.cd_forma_pagamento == 4')
                                                i.jam.jam-phone
                                            div.icon-box(v-if='elem.cd_forma_pagamento == 5')
                                                i.jam.jam-credit-card
                                            div.icon-box(v-if='elem.cd_forma_pagamento == 6')
                                                i.jam.jam-id-card
                                            p.text-method {{ elem.cd_forma_pagamento_f }}
                                            p.text-value {{ elem.vl_pago_f }}
                                            p.text-method(style='font-size: 12px !important') <b>Destino: </b>
                                                span {{ elem.cd_conta_recebimento_f }}

                            .p-col-3(@click='handleFormaPagamentoDialog()' v-if='resta != 0')
                                Card.payment-box(style='cursor: pointer')
                                    template(slot='content')
                                        .ta-center
                                            div.icon-box
                                                i.jam.jam-plus
                                            p.text-method Adicionar forma de pagamento
                .p-col-12.p-grid.p-fluid
                    .p-col-3
                    .p-col-3
                    .p-col-3
                    .p-col-3.ta-right(v-if='!resta == 0')
                        span.info-resta (Resta {{ $root.formatPrice(resta) }})
                .p-col-12
                    ProgressBar(v-if="waitingComprovanteBaixa" mode="indeterminate")
                    .p-inputgroup(v-else)
                        .p-input-icon-left.p-input-icon-right
                            InputText(type="text" placeholder="Anexar comprovante" v-model="comprovanteBaixa.name"
                                @click="$refs.comprovanteBaixa.click()" readonly)
                            i.jam.jam-attachment(@click="$refs.comprovanteBaixa.click()" v-tooltip.top="'Anexar'")
                        Button.p-button-success(
                            style="margin-right: flex-end;"
                            icon="pi pi-plus"
                            v-tooltip.top="'Adicionar comprovante'"
                            @click="$refs.comprovanteBaixa.click()")
                    input(
                        v-show='false'
                        type="file"
                        ref="comprovanteBaixa"
                        @change="anexarComprovanteBaixa()"
                        accept=".pdf, image/png, image/jpeg")
                .p-col-12
                    Button(v-if='!waitingBaixarParcela' label='Salvar' @click='baixarParcela()')
                    ProgressBar(v-if='waitingBaixarParcela' mode="indeterminate")

        Dialog.dialogAdicionar(header='Adicionar forma de pagamento' :modal='true' :visible.sync='dialog.formaPagamento')
          .p-grid.p-fluid
            .p-col-6
                label.form-label Método:
                Dropdown(
                    v-model='formaPagamentoModel.cd_forma_pagamento'
                    :options='options.formasPagamento'
                    optionLabel='label'
                    optionValue='value'
                    dataKey='value'
                )
            .p-col-6
                label.form-label Valor:
                .p-inputgroup
                    span.p-inputgroup-addon R$
                    currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled.ta-right(
                        locale='pt-BR' :auto-decimal-mode='true' v-model='formaPagamentoModel.vl_pago')
            .p-col-12
                label.form-label Conta de Recebimento:
                Dropdown( :options='options.contas' v-model='formaPagamentoModel.cd_conta_recebimento'
                    dataKey='value' optionLabel='label' optionValue='value' placeholder='Selecione')
            .p-col-12.mt-2.ta-center
                Button(label='Adicionar' icon="jam jam-plus" type="button" @click='adicionarFormaPagamento()')


        Dialog(header='Editar Parcela' :visible.sync='dialog.parcela' :modal='true' :contentStyle="{'width':'500px'}")
            .p-grid.p-fluid.p-justify-center(style='margin: 0 auto;')
                .p-col-12
                    label.form-label Data de Vencimento:
                    .p-inputgroup
                        Calendar( dateFormat="dd/mm/yy" :locale="ptbr" v-model='parcelaModel.dt_vencimento' appendTO='body'
                            :manualInput='false' placeholder='DD/MM/YYYY')
                .p-col-12
                    label.form-label Valor:
                    .p-inputgroup
                        span.p-inputgroup-addon R$
                        InputText(type='text' placeholder='Valor' v-model='parcelaModel.vl_titulo' )
                .p-col-12
                    Button(v-if='!waitingParcela' label='Salvar' @click='editarParcela()')
                    ProgressBar(v-if='waitingParcela' mode="indeterminate")


        form(@submit.prevent='handleSubmit()')
            ProgressBar(v-if='waitingForm' mode="indeterminate")
            div(v-else)
                Panel(:header="`${ model.id ? 'Visualizar' : 'Adicionar' } conta a receber`")
                    .p-grid.p-fluid.p-justify-center(style='margin: 0 auto;')
                        .p-col-2(:class="{ 'form-group--error': submitted && $v.model.dt_competencia.$error }")
                            label.form-label Data de Competência:
                            .p-inputgroup
                                Calendar(:disabled='isReadOnly' dateFormat="dd/mm/yy" :locale="ptbr" v-model='model.dt_competencia'
                                    :manualInput='false' placeholder='DD/MM/YYYY')
                            .feedback--errors(v-if='submitted && $v.model.dt_competencia.$error')
                                    .form-message--error(v-if="!$v.model.dt_competencia.required") <b>Data de Competência</b> é obrigatória.

                        .p-col-3(:class="{ 'form-group--error': submitted && $v.model.ds_descricao.$error }")
                            label.form-label Descrição:
                            InputText(:disabled='isReadOnly' type='text' placeholder='Descrição' v-model='model.ds_descricao')
                            .feedback--errors(v-if='submitted && $v.model.ds_descricao.$error')
                                .form-message--error(v-if="!$v.model.ds_descricao.required") <b>Descrição</b> é obrigatória.

                        .p-col-2(:class="{ 'form-group--error': submitted && $v.model.ie_tipo_credor.$error }")
                            label.form-label Tipo de Cliente:
                            Dropdown(:disabled='isReadOnly' :options='options.ie_tipo' v-model='model.ie_tipo_credor'
                                dataKey='value' optionLabel='label' optionValue='value' placeholder='Selecione' :filter='true')
                            .feedback--errors(v-if='submitted && $v.model.ie_tipo_credor.$error')
                                .form-message--error(v-if="!$v.model.ie_tipo_credor.required") <b>Tipo de Cliente</b> é obrigatório.

                        .p-col-5(v-if="model.id" )
                            label.form-label {{ model.ie_tipo_credor == 'PF' ? 'Cliente' : 'Fornecedor'}}:
                            InputText(:disabled='isReadOnly' type='text' placeholder='Descrição' v-model='model.ds_descricao')
                        .p-col-5(v-if="!model.id" )
                            BuscarFornecedor(v-show='model.ie_tipo_credor == "PJ"' ref='buscarFornecedor' label='Fornecedor')
                            BuscarPaciente(v-show='model.ie_tipo_credor == "PF"' ref='buscarPaciente' label='Cliente')
                        .p-col-4
                            label.form-label Código de Referência:
                            InputText(:disabled='isReadOnly' type='text' placeholder='(Opcional)')

                        .p-col-4
                            label.form-label NSU:
                            InputText(:disabled='isReadOnly' type='text' placeholder='(Opcional)')

                        .p-col-4(:class="{ 'form-group--error': submitted && $v.model.cd_plano_contas.$error }")
                            label.form-label Conta Financeira:
                            Dropdown(:disabled='isReadOnly' :options='options.contaFinanceiraOption' v-model='model.cd_plano_contas'
                                dataKey='value' optionLabel='label' optionValue='value' placeholder='Selecione' :filter='true')
                            .feedback--errors(v-if='submitted && $v.model.cd_plano_contas.$error')
                                .form-message--error(v-if="!$v.model.cd_plano_contas.required") <b>Conta Financeira</b> é obrigatória.

                        .p-col-3(v-if="model.id ")
                            label.form-label Movimento:
                            InputText(type='text' disabled)

                        .p-col-3(v-if="model.id ")
                            label.form-label Operadora:
                            InputText(type='text' disabled)

                        .p-col-3(v-if="model.id ")
                            label.form-label Forma de Pagamento:
                            InputText(type='text' disabled)

                        .p-col-3(v-if="model.id ")
                            label.form-label Bandeiras:
                            InputText(type='text' disabled)

                        .p-col-4(v-if="model.id ")
                            label.form-label Valor Bruto:
                            .p-inputgroup
                                span.p-inputgroup-addon R$
                                currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled.ta-right(
                                     locale='pt-BR' :auto-decimal-mode='true' disabled)

                        .p-col-4(v-if="model.id ")
                            label.form-label Valor Taxas Operadora:
                            .p-inputgroup
                                span.p-inputgroup-addon R$
                                currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled.ta-right(
                                 locale='pt-BR' :auto-decimal-mode='true' disabled)

                        .p-col-4(v-if="model.id ")
                            label.form-label Valor Juros:
                            .p-inputgroup
                                span.p-inputgroup-addon R$
                                currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled.ta-right(
                                    locale='pt-BR' :auto-decimal-mode='true' disabled)

                        .p-col-4(:class="{ 'form-group--error': submitted && $v.model.vl_titulo.$error }")
                            label.form-label Valor:
                                i(class='jam jam-info' style='color: #187EF6' v-tooltip.top="'Valor líquido'")
                            .p-inputgroup
                                span.p-inputgroup-addon R$
                                currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled.ta-right(:disabled='isReadOnly'
                                    locale='pt-BR' :auto-decimal-mode='true' v-model='model.vl_titulo' )
                            .feedback--errors(v-if='submitted && $v.model.vl_titulo.$error')
                                .form-message--error(v-if="!$v.model.vl_titulo.required") <b>Valor</b> é obrigatória.
                                .form-message--error(v-if="!$v.model.vl_titulo.minValue") <b>Valor</b> deve ser maior que 0,01.

                        .p-col-4
                            label.form-label Parcelas:
                            Dropdown(:disabled='isReadOnly' :options='options.parcelas' v-model='model.qt_parcelas'
                                dataKey='value' optionLabel='value' optionValue='value' placeholder='Selecione')

                        .p-col-4
                            label.form-label Data de Vencimento:
                            .p-inputgroup
                                Calendar(:disabled='isReadOnly' dateFormat="dd/mm/yy" :locale="ptbr"  v-model='model.dt_vencimento'
                                    :manualInput='false' placeholder='DD/MM/YYYY')

                        .p-col-4.ta-left
                            label.form-label Possui Adiantamento:
                            InputSwitch( v-model='model.ie_possui_entrada')

                        .p-col-4
                            label.form-label Valor do Adiantamento:
                            .p-inputgroup
                                span.p-inputgroup-addon R$
                                currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled.ta-right(
                                    locale='pt-BR' :auto-decimal-mode='true' v-model='model.vl_entrada' :disabled='model.id != 0 && !model.ie_possui_entrada')


                        .p-col-4
                            label.form-label Data do Adiantamento:
                            .p-inputgroup
                                Calendar(dateFormat="dd/mm/yy" :locale="ptbr" v-model='model.dt_vencimento_entrada'
                                    :manualInput='false' placeholder='DD/MM/YYYY' :disabled='model.id != 0 && !model.ie_possui_entrada')

                        .p-col-12
                            ProgressBar(v-if="waitingComprovante" mode="indeterminate")
                            .p-inputgroup(v-else)
                                .p-input-icon-left.p-input-icon-right
                                    InputText(type="text" placeholder="Anexar comprovante" v-model="model.comprovante"
                                        @click="$refs.comprovanteEstorno.click()" readonly)
                                    i.jam.jam-attachment(@click="$refs.comprovanteEstorno.click()" v-tooltip.top="'Anexar'")
                                Button.p-button-success(
                                    style="margin-right: flex-end;"
                                    icon="pi pi-plus"
                                    v-tooltip.top="'Adicionar comprovante'"
                                    @click="$refs.comprovanteEstorno.click()")
                            input(
                                v-show='false'
                                type="file"
                                ref="comprovanteEstorno"
                                @change="anexarComprovanteEstorno()"
                                accept=".pdf, image/png, image/jpeg")

                        .p-col-12
                            label.form-label Observação:
                            Textarea(:disabled='isReadOnly' :autoResize="true" rows="5" v-model='model.ds_observacao')

                        .p-col-3
                            label.form-label Repetir Lançamento:
                            InputSwitch(:disabled='isReadOnly' v-model='model.ie_continuidade')

                        .p-col-2
                            label.form-label Frequência:
                            Dropdown(:disabled='isReadOnly' :options='options.frequencia' v-model='model.ie_frequencia'
                                dataKey='value' optionLabel='label' optionValue='value' placeholder='Selecione' :filter='true')

                        .p-col-3
                            label.form-label Quantidade de Repetições:
                            InputText(:disabled='isReadOnly' type='text' v-model='model.qt_repeticoes')

                        .p-col-2
                            label.form-label Data Inicial de Vigência:
                            .p-inputgroup
                                Calendar(:disabled='isReadOnly' dateFormat="dd/mm/yy" :locale="ptbr"  v-model="model.dt_vigencia_inicial"
                                    :manualInput='false' placeholder='DD/MM/YYYY')

                        .p-col-2
                            label.form-label Data Final de Vigência:
                            .p-inputgroup
                                Calendar(:disabled='isReadOnly' dateFormat="dd/mm/yy" :locale="ptbr"  v-model="model.dt_vigencia_final"
                                    :manualInput='false' placeholder='DD/MM/YYYY')
                        .p-col-2(v-if="model.id ")
                            label.form-label Alq ISS:
                            InputText(type='text' disabled)
                        .p-col-2(v-if="model.id ")
                            label.form-label Alq IRPJ:
                            InputText(type='text' disabled)
                        .p-col-2(v-if="model.id ")
                            label.form-label Alq PIS:
                            InputText(type='text' disabled)
                        .p-col-2(v-if="model.id ")
                        .p-col-2(v-if="model.id ")
                        .p-col-2(v-if="model.id ")


                        .p-col-12(v-if='model.id')
                            Panel.datatable(header='Parcelas')
                                DataTable(:value="model.parcelas")
                                    Column(headerStyle='width: 15%;' bodyStyle='text-align: center;' field='nr_parcelas' header='Nº da Parcela')
                                        template(#body='props')
                                            span {{ props.data.nr_parcela }} / {{ model.parcelas.length }}
                                    Column(headerStyle='width: 15%;' bodyStyle='text-align: center;' field='vl_titulo_f' header='Valor')
                                    Column(headerStyle='width: 15%;' bodyStyle='text-align: center;' field='dt_vencimento_f' header='Data de Vencimento')
                                    Column(headerStyle='width: 15%;' bodyStyle='text-align: center;' field='dt_liquidacao_f' header='Data de Liquidação')
                                    Column(headerStyle='width: 15%;' bodyStyle='text-align: center;' field='forma_pagamento' header='Forma de Pagamento')
                                    Column(headerStyle='width: 15%;' bodyStyle='text-align: center;' field='vl_titulo_f' header='Valor')
                                    Column(headerStyle='width: 15%;' bodyStyle='padding: 1px; height: 1px; text-align: center;' field='situacao' header='Status')
                                        template(#body='props')
                                            .cell(:style="{'background-color': props.data.ds_situacao_cor}")
                                                span <b> {{ props.data.ds_situacao_f }} </b>
                                    Column(headerStyle='width: 15%; text-align: center' header='Ações')
                                        template(#body='props')
                                            .ta-center
                                                Button.p-button-success.p-button-raised.p-button-rounded.mr-1(
                                                    v-tooltip.top="'Pagar'"
                                                    icon="jam jam-coin"
                                                    type='button'
                                                    @click='handlePagarDialog(props.data)'
                                                    v-if="props.data.ie_situacao != 'PG' && props.data.ie_situacao != 'CA' "
                                                )
                                                Button.p-button-raised.p-button-rounded.mr-1(
                                                    v-tooltip.top="'Editar'"
                                                    icon="jam jam-write"
                                                    type='button'
                                                    v-if="props.data.ie_situacao != 'PG' && props.data.ie_situacao != 'CA' "
                                                    @click='handleParcelaDialog(props.data)'
                                                )
                                                Button.p-button-raised.p-button-rounded.p-button-danger(
                                                    v-tooltip.top="'Cancelar parcela'"
                                                    icon="jam jam-close-circle-f"
                                                    type='button'
                                                    v-if="props.data.ie_situacao != 'PG' && props.data.ie_situacao != 'CA' "
                                                    @click='handleCancelarParcela(props.data)'
                                                )
                                                Button.p-button-raised.p-button-rounded.p-button-warning(
                                                    v-tooltip.top="'Estornar parcela'"
                                                    icon='pi custom-svg-icon'
                                                    type='button'
                                                    disabled
                                                    v-if="props.data.ie_situacao == 'PG'  && props.data.ie_situacao != 'CA' "
                                                )

                        .p-col-12.mt-2(v-if="model.id")
                            .p-grid
                                .p-col-12.p-md-6.aux-2
                                    span <b>Usuário criação: </b> {{ model.nm_usuario_cri }}
                                .p-col-12.p-md-6.aux-2
                                    span <b>Data da criação: </b> {{ model.dt_criado_f }}

                        .p-col-4.mt-2.ta-center
                            Button(v-if='!model.id' label='Salvar' type="submit")
</template>

<style lang="scss">
    .caixas-salvar {
        .p-dropdown-panel {
            position: absolute;
            z-index: 1000;
        }
        .info-resta {
            font-size: 15px;
            font-weight: 700;
            color: #888;
        }
        .custom-svg-icon {
			background: url("../../assets/img/estorno.svg");
            top: 9.5px;
            left: 9px;
			height: 23px;
			width: 23px;
			color: white
		}
        .payment-box {
            position: relative;
            .p-button-success {
                .cursor-pointer {
                    cursor: not-allowed !important;
                }
            }
                height: 250px;
                display: flex;
                justify-content: center;
                align-items: center;
            .text-method {
                font-size: 18px;
                margin: 0;
                margin-top: 14px;
            }
            .text-value {
                font-size: 22px;
                font-weight: 700;
                margin: 0;
                margin-top: 10px;
            }
            .text-parcelas {
                font-size: 15px;
                margin-top: 14px;
            }
            .icon-box {
                width: 50px;
                height: 50px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                background-color: #312c66;
                i {
                    font-size: 20px;
                    color: #fff;
                }
            }
            .btn-remove-payment {
                display: inline-block;
                position: absolute;
                top: 2px;
                right: 2px;
                background-color: #c8c8c8;
                width: 20px;
                height: 20px;
                text-align: center;
                line-height: 16px;
                border-radius: 50%;
                i {
                    font-size: 20px;
                    color: #666;
                }
            }
        }
        .dialogRemover,
        .dialogAdicionar{
            width: 96%;
            max-width: 500px;
            .p-dialog-content {
                overflow: visible !important;
            }
        }
        .dialogCancelar {
            width: 20%;
            .p-progress-spinner {
                width: 29px;
                height: auto;
            }
        }
        .dialogAdicionarBaixar {
            width: 98%;
            max-width: 750px;
            .p-dialog-content {
                overflow: scroll !important;
            }
        }
        .custom-svg-icon-1 {
			background: url("../../assets/img/estorno.svg");
			top: 9.5px;
            left: 10px;
			height: 23px;
			width: 23px;
			color: white
		}
        .aux-1 { text-align: center; @media all and (max-width: 576px) { text-align: left; } }
        .p-inputtext[disabled=disabled] {
            color: #000;
            font-weight: 600;
            background-color: #ddd;
        }
        .p-picklist-source-controls,
        .p-picklist-target-controls {
            display: none;
        }
        .p-picklist-source-wrapper,
        .p-picklist-target-wrapper {
            width: 45%;
            @media all and (max-width: 576px) {
                width: 100%;
            }
        }
        .p-picklist-buttons-cell {
            text-align: center;
            .p-button {
                display: none !important;
                &:first-of-type, &:nth-of-type(3) {
                    display: inline-block !important;
                }
            }
        }
        .text-box {
            margin: 0;
            font-size: 16px;
        }
        .input-sexo .p-button {
            width: 50% !important;
        }
        .p-input-icon-left,
        .p-input-icon-right {
            position: relative;
            display: inline-block;
            width: 100%;
        }
        .p-input-icon-left > i,
        .p-input-icon-right > i {
            position: absolute;
            top: 50%;
            margin-top: -0.5rem;
            cursor: pointer;
        }
        .p-input-icon-left > i:first-of-type {
            left: 0.5rem;
            color: #6c757d;
        }
        .p-input-icon-right > i:last-of-type {
            right: 0.5rem;
            color: #6c757d;
        }
        .p-input-icon-left > .p-inputtext {
            padding-left: 2rem;
        }
        .p-input-icon-right > .p-inputtext {
            padding-right: 2rem;
        }

        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
            .cell {
                height: 100%;
                align-content: center;
                text-align: center;
            }
        }
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Tooltip from 'primevue/tooltip'
    import MultiSelect from 'primevue/multiselect'
    import Calendar from 'primevue/calendar'
    import Textarea from 'primevue/textarea'
    import Message from 'primevue/message'
    import InputSwitch from 'primevue/inputswitch'
    import DataTable from 'primevue/datatable'
    import Dialog from 'primevue/dialog'
    import Card from "primevue/card";

    import Column from 'primevue/column'
    import { pCalendarLocale_ptbr, processDominiosResponse } from './../../utils'
    import { PlanoContas, DominioValor, Caixas, ContasBancarias, ContasReceber } from './../../middleware'
    import { required, minValue } from 'vuelidate/lib/validators'
    import moment from "moment"
    import BuscarFornecedor from './BuscarFornecedor'
    import BuscarPaciente from './../Paciente/BuscarPaciente'



    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Password, Calendar, Textarea, DataTable, Column, BuscarFornecedor, BuscarPaciente,
                InputMask, SelectButton, Dropdown, Tooltip, MultiSelect, Message, InputSwitch, Dialog, Card
        },
        directives: { tooltip: Tooltip },
        created () {
            this.waiting = true
            Promise.all([
                this.getContaFinanceira(),
                this.getContaBancaria(),
                this.getDominios(),
                this.getFormasPagamento(),
            ]).then(() => {
                let id = null
                if (! isNaN(this.$route.params.id)) id = parseInt(this.$route.params.id)
                if (id) {
                    this.isReadOnly = true
                    ContasReceber.find(id).then(response => {
                        if (response.status == 200) {
                            let keys = Object.keys(this.model)
                            keys.forEach(key => {
                                if (key.substr(0, 3) == 'nr_') this.model[key] = parseInt(response.data[key])
                                else if (key !== "ie_tipo") this.model[key] = response.data[key]
                            })

                            this.model.id = id
                            this.model.dt_competencia = this.model.dt_competencia ? new Date(this.model.dt_competencia) : null
                            this.model.dt_vencimento = this.model.dt_vencimento ? new Date(this.model.dt_vencimento) : null
                            this.model.dt_vigencia_final = this.model.dt_vigencia_final ? new Date(this.model.dt_vigencia_final) : null
                            this.model.dt_vigencia_inicial = this.model.dt_vigencia_inicial ? new Date(this.model.dt_vigencia_inicial) : null
                            this.model.dt_criado_f = moment(response.data.dt_criado).format('DD/MM/YYYY HH:mm')

                            if (response.data.dt_atualizado) {
                                this.model.nm_usuario_edi = response.data.nm_usuario_edi
                                this.model.dt_atualizado_f = moment(response.data.dt_criado).format('DD/MM/YYYY HH:mm')
                            }
                            this.model.parcelas.forEach(item => {
                                item.dt_vencimento_f = moment(item.dt_vencimento).format("DD/MM/YYYY")
                                item.dt_liquidacao_f = item.dt_liquidacao ? moment(item.dt_liquidacao).format("DD/MM/YYYY") : '-'
                                item.vl_titulo_f = this.$root.formatPrice(item.vl_titulo)
                                item.ds_situacao_cor = this.getSituacao(item.ie_situacao)?.['ds_cor'] || '#fff';
                                item.ds_situacao_f = this.getSituacao(item.ie_situacao)?.['ds_valor']
                            })
                        }
                        this.waiting = false
                    })
                } else this.waiting = false

            })

        },
        data () {
            return {
                model: {
                    vl_titulo: 0,
                    vl_entrada: 0,
                    ds_descricao: '',
                    ds_observacao: '',
                    dt_transferencia: null,
                    dt_vencimento: null,
                    dt_competencia: null,
                    dt_vigencia_final: null,
                    dt_vigencia_inicial: null,
                    dt_vencimento_entrada: null,
                    cd_origem: null,
                    qt_parcelas: 0,
                    ie_continuidade: false,
                    ie_possui_entrada: false,
                    ie_frequencia: null,
                    ie_tipo_credor: null,
                    cd_destino: null,
                    cd_pessoa_fisica: null,
                    cd_pessoa_juridica: null,
                    cd_plano_contas: null,
                    cd_unidade: 10,
                    qt_repeticoes: null,
                    comprovante: null,
                    nm_usuario_cri: '',
                    parcelas: [],
                    tributos: []
                },
                options: {
                    ie_tipo: [],
                    parcelas: [
                        {
                            value: 1,
                        },
                        {
                            value: 2,
                        },
                        {
                            value: 3,
                        },
                        {
                            value: 4,
                        },
                        {
                            value: 5,
                        },
                        {
                            value: 6,
                        },
                        {
                            value: 7,
                        },
                        {
                            value: 8,
                        },
                        {
                            value: 9,
                        },
                        {
                            value: 10,
                        },
                        {
                            value: 11,
                        },
                        {
                            value: 12,
                        },

                    ],
                    situacao: [],
                    frequencia: [],
                    formasPagamento: [],
                    contas: [],
                    tipo_baixa: [],
                    situacao_baixa: []
                },
                dialogBaixa: {
                    visible: false,
                    data: null
                },
                dialogCancelar: {
                    visible: false,
                    data: null
                },
                dialog: {
                    parcela: false,
                    pagar: false,
                    formaPagamento: false,
                },
                parcelaModel: {
                    dt_vencimento: '',
                    vr_parcela: null,
                },
                formaPagamentoModel: {
                    cd_forma_pagamento: null,
                    vl_pago: null,
                    cd_conta_recebimento: null,
                },
                baixaModel : {
                    cd_titulo_receber: null,
                    cd_banco: null,
                    dt_baixa: null,
                    cd_tipo_baixa: 'BM',
                    ie_situacao: 'PA',
                    vl_baixa: 0,
                    vl_multa: 0,
                    vl_juros: 0,
                    vl_impostos_totais: 0,
                    vl_desconto: 0,
                    vl_pago: 0,
                    formas_pagamento: []
                },
                isReadOnly: false,
                comprovanteBaixa: {},
                formasPagamentoList: [],
                dominios: null,
                ptbr: pCalendarLocale_ptbr,
                waiting: true,
                waitingForm: false,
                waitingComprovante: false,
                waitingComprovanteBaixa: false,
                waitingBaixarParcela: false,
                waitingParcela: false,
                waitingCancelarParcela: false,
                submitted: false
            }
        },
        validations () {
            let v = {
                model: {
                    dt_competencia: { required },
                    dt_vencimento: {required},
                    ds_descricao: { required },
                    ds_observacao: { required },
                    ie_tipo_credor: { required },
                    vl_titulo: {
                        required,
                        minValue: minValue(0.01),
                    },
                    cd_plano_contas: { required },
                }
            }

            return v
        },
        watch: {
            "baixaModel.vl_desconto": function(valor) {
                if(valor > 0) {
                    this.baixaModel.vl_juros = 0
                    this.baixaModel.vl_multa = 0
                }
            },
            "baixaModel.vl_juros": function(valor) {
                if(valor > 0) {
                    this.baixaModel.vl_desconto = 0
                }
            },
            "baixaModel.vl_multa": function(valor) {
                if(valor > 0) {
                    this.baixaModel.vl_desconto = 0
                }
            },
        },
        computed: {
            resta() {
                var totalNovosPagamentos =
                    this.formasPagamentoList.length > 0
                    ? this.formasPagamentoList.reduce(
                        (accumalator, currentValue) =>
                            accumalator + currentValue.vl_pago,
                        0
                        )
                    : 0;
                var valorPagar = +this.baixaModel.vl_baixa + this.baixaModel.vl_impostos_totais + this.baixaModel.vl_juros + this.baixaModel.vl_multa

                if(this.baixaModel.vl_desconto > 0)
                    valorPagar -= this.baixaModel.vl_desconto
                return parseFloat(valorPagar - totalNovosPagamentos);
            },
        },
        methods: {
            async getContaFinanceira () {
                await PlanoContas.findAll({ie_tipo: 'A'}).then(response => {
                    this.options.contaFinanceiraOption = [{value: null, label: 'Selecione'}]
                    if (([200, 201]).includes(response.status)) {
                        response.data.forEach(i => {
                            this.options.contaFinanceiraOption.push({
                                value: i.id,
                                label: i.ds_descricao,
                            })
                        })
                    } else {
                        this.$toast.error("Houve um problema ao carregar as contas financeiras.", { duration: 3000 });
                    }
                })
            },
            getSituacao(ie_valor) {
                return this.dominios['SITUACAO_CONTAS_RECEBER']?.[ie_valor];
            },
            async getDominios () {
                await DominioValor.findAll({ds_mnemonico: ['TIPO_PESSOA', 'SITUACAO_CONTAS_RECEBER', 'FREQUENCIA', 'CODIGOS_TIPO_BAIXA', 'SITUACAO_TITULO_BAIXA']}).then((response) => {
                    this.dominios = processDominiosResponse(response)
					if(response.status === 200) {
                        response.data['SITUACAO_CONTAS_RECEBER'].valores.forEach(item => {
							this.options.situacao.push({
                                label: item.ds_valor,
                                value: item.ie_valor,
                                ds_cor: item.ds_cor
                                })
                        })
                        response.data['TIPO_PESSOA'].valores.forEach(item => {
							this.options.ie_tipo.push({label: item.ds_valor, value: item.ie_valor})
						})
                        response.data['FREQUENCIA'].valores.forEach(item => {
							this.options.frequencia.push({label: item.ds_valor, value: item.ie_valor})
						})
                        response.data['CODIGOS_TIPO_BAIXA'].valores.forEach(item => {
							this.options.tipo_baixa.push({label: item.ds_valor, value: item.ie_valor})
						})
					}
				})
            },
            async getContaBancaria(){
                await ContasBancarias.findAll().then((response) => {
                    if (response.status == 200) {
                        response.data.forEach((elem) => {
                            this.options.contas.push({
                                value: elem.id,
                                label: `${elem.nr_agencia} / ${elem.nr_conta} / ${elem.nm_banco}`,
                            });
                        });
                    }
                });
            },
            async getFormasPagamento() {
                await Caixas.getFormasPagamento().then((response) => {
                    if (response.status == 200) {
                        response.data.forEach((elem) => {
                            this.options.formasPagamento.push({
                                value: elem.id,
                                label: elem.nm_forma_pagamento,
                            });
                        });
                    }
                });
            },
            // anexarComprovanteEstorno() {
            //     const files = this.$refs.comprovanteEstorno.files;
            //     const comp = files.length && files[0];
            //     if (!comp) return;
            // },
            handleParcelaDialog(data) {
                this.parcelaModel.dt_vencimento = data.dt_vencimento
                this.parcelaModel.vl_titulo = data.vl_titulo
                this.dialog.parcela = true
            },
            handlePagarDialog(data) {
                this.dialogBaixa.data = data
                this.baixaModel.vl_baixa = data.vl_titulo
                this.dialogBaixa.visible = true
            },
            handleFormaPagamentoDialog() {
                this.formaPagamentoModel = {
                    cd_forma_pagamento: null,
                    vl_pago: null,
                    cd_conta_recebimento: null,
                },
                this.dialog.formaPagamento = true
            },
            handleCancelarParcela(data) {
                this.dialogCancelar.data = data
                this.dialogCancelar.visible = true
            },
            editarParcela() {
                console.log("PARCELA EDITADA", this.parcelaModel)
            },
            cancelarParcela() {
                this.waitingCancelarParcela = true
                ContasReceber.cancelarParcela(this.dialogCancelar.data.id).then(response => {
                    if([200, 201, 204].includes(response.status)) {
                        this.$toast.success("Parcela cancelada com sucesso");
                        this.dialogCancelar.visible = false
                        location.reload()
                    } else if (response.data.detail) {
                        this.toastResponseDetail(response.data.detail);
                        this.waitingCancelarParcela = false
                    } else {
                        this.toastResponseDetail("Falha ao cancelar a parcela.");
                        this.waitingCancelarParcela = false
                    }
                })
            },
            adicionarFormaPagamento() {
                var a = {
                    cd_forma_pagamento: this.formaPagamentoModel.cd_forma_pagamento,
                    cd_forma_pagamento_f: this.options.formasPagamento.find(i => i.value === this.formaPagamentoModel.cd_forma_pagamento).label,
                    vl_pago: this.formaPagamentoModel.vl_pago,
                    vl_pago_f: this.$root.formatPrice(this.formaPagamentoModel.vl_pago),
                    cd_conta_recebimento_f: this.options.contas.find(i => i.value === this.formaPagamentoModel.cd_conta_recebimento).label,
                    cd_conta_recebimento: this.formaPagamentoModel.cd_conta_recebimento
                }
                this.formasPagamentoList.push(a)
                this.dialog.formaPagamento = false
            },
            baixarParcela() {

                if(this.baixaModel.dt_baixa == null) {
                    this.$toast.warning(`Insira uma data de recebimento`)
                    return
                }

                if(this.resta != 0) {
                    this.$toast.warning(`Ainda resta R$ ${this.resta} para completar a baixa.`)
                    return
                }

                if(this.baixaModel.dt_baixa == null) {
                    this.$toast.warning(`Insira uma data de recebimento`)
                    return
                }
                this.waitingBaixarParcela = true;

                let dataSend = {
                    cd_titulo_receber: this.dialogBaixa.data.id,
                    cd_banco: this.baixaModel.cd_banco,
                    dt_baixa: moment(this.baixaModel.dt_baixa).format("YYYY-MM-DD"),
                    cd_tipo_baixa: this.baixaModel.cd_tipo_baixa,
                    ie_situacao: this.baixaModel.ie_situacao,
                    vl_baixa: this.baixaModel.vl_baixa,
                    vl_multa: this.baixaModel.vl_multa,
                    vl_juros: this.baixaModel.vl_juros,
                    vl_desconto: this.baixaModel.vl_desconto,
                    vl_pago: this.baixaModel.vl_pago,
                    nm_usuario_cri: this.model.nm_usuario_cri,
                    formas_pagamento: this.formasPagamentoList
                }

                ContasReceber.baixaParcela(dataSend.cd_titulo_receber, dataSend).then((response) => {
                    this.waitingBaixarParcela = false;
                    if (response.status === 200) {
                        this.$toast.success("Baixa realizada com sucesso.");
                        this.dialogBaixa.visible = false
                        location.reload()
                    } else if (response.data.detail) {
                        this.toastResponseDetail(response.data.detail);
                    } else {
                        this.toastResponseDetail("Falha ao realizar a baixa.");
                    }
                });
            },
            anexarComprovanteBaixa() {
                const files = this.$refs.comprovanteBaixa.files;
                const comp = files.length && files[0];
                if (!comp) return;

                let cd_titulo = this.dialogBaixa.data.id

                let dataSend = {
                    aq_comprovante_pagamento: comp,
                    cd_titulo: cd_titulo,
                };

                this.waitingComprovanteBaixa = true;
                ContasReceber.anexarComprovante(dataSend).then((response) => {
                    this.waitingComprovanteBaixa = false;
                    if (response.status === 200) {
                        this.comprovanteBaixa = files[0];
                        this.$toast.success("Comprovante anexado com sucesso.");
                    } else if (response.data.detail) {
                        this.toastResponseDetail(response.data.detail);
                    } else {
                        this.toastResponseDetail("Falha ao enviar comprovante.");
                    }
                });
            },
            handleSubmit () {
                this.submitted = true

                this.$v.$touch()
                if (this.$v.$invalid) return 0

                this.waitingForm = true
                var dataSend = {
                    ie_tipo_credor: this.model.ie_tipo_credor,
                    regra: {
                        cd_plano_contas: this.model.cd_plano_contas,
                        cd_unidade: 12,
                        cd_pessoa_fisica: this.$refs.buscarPaciente?.cd_pessoa_fisica ? this.$refs.buscarPaciente?.cd_pessoa_fisica : null ,
                        cd_pessoa_juridica: this.$refs.buscarFornecedor?.cd_pessoa_juridica ? this.$refs.buscarPaciente?.cd_pessoa_fisica : null,
                        ds_descricao: this.model.ds_descricao,
                        ds_observacao: this.model.ds_observacao,
                        dt_competencia: this.model.dt_competencia ? moment(this.model.dt_competencia).format("YYYY-MM-DD") : null,
                        dt_vencimento: this.model.dt_vencimento ? moment(this.model.dt_vencimento).format("YYYY-MM-DD") : null,
                        dt_vencimento_entrada: this.model.dt_vencimento_entrada ?  moment(this.model.dt_vencimento_entrada).format("YYYY-MM-DD") : null,
                        dt_vigencia_final: this.model.dt_vigencia_final ? moment(this.model.dt_vigencia_final).format("YYYY-MM-DD")  : null,
                        dt_vigencia_inicial: this.model.dt_vigencia_inicial ? moment(this.model.dt_vigencia_inicial).format("YYYY-MM-DD") :  null,
                        ie_continuidade: this.model.ie_continuidade,
                        ie_possui_entrada: this.model.ie_possui_entrada,
                        ie_frequencia: this.model.ie_frequencia,
                        qt_parcelas: this.model.qt_parcelas,
                        qt_repeticoes: this.model.qt_repeticoes,
                        vl_entrada: this.model.vl_entrada,
                        vl_titulo: this.model.vl_titulo
                    }
                }
                ContasReceber.save(dataSend).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        this.$toast.success('Conta a receber criada com sucesso', { duration: 3000 })
                        this.$router.push({ name: 'contas-receber' })
                        this.$router.go()
                    } else {
                        if (response.data.detail) {
                            if (typeof response.data.detail == 'string') {
                                this.$toast.error(response.data.detail, { duration: 3000 });
                            } else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        } else if (response.data.non_field_errors) {
                            response.data.non_field_errors.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        }
                    }
                    this.waitingForm = false
                })
            }
         }
      }
</script>
